<template>
  <div class="goods" v-if="obj" id="goods">
    <Head :show="true" :bg="true" :yuan="true" :right="true">
      <van-button class="btn-share-head" @click="shareshowAction"> <i class="iconfont iconfenxiang"></i> </van-button>
    </Head>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item,index) in obj.show_pic" :key="index">
        <img v-lazy="item" alt="">
      </van-swipe-item>
    </van-swipe>
    <div class="main" v-if="goodShow"  v-cloak>
      <div class="main-bgfff" v-if="$route.query.type=='tt'">
        <div class="title">
          <h6><span>{{obj.qishu_trans}}</span> </h6>
          <h5>{{obj.title}}</h5>
          <p class="back" @click="gotoJiexiao">{{$t('home.before_zhongjiang')}} >></p>
        </div>
        <div>
            <div class="timeout" v-if="isend!=1">
              <div class="p1" v-if="!waitFlag">
                <img class="item-lou" :src="require('@/assets/gif/lou.gif')" alt="">
                <van-count-down   @finish="timeEndAction" millisecond class="item-end" format="HH:mm:ss:SS" :time="timeCount"  >
                </van-count-down>
              </div>
              <p class="p2" v-if="!waitFlag"><span>{{$t('home.now_price')}}</span><span>$ {{changettData.now_price ? (changettData.now_price ) : "0.00"  | number_format}} </span></p>
              <p class="p_wait" v-if="waitFlag"><van-loading class="p-icon" color="#DF561C" />{{$t('add.wait_for_result')}}</p>
            </div>
            <div class="timeend" v-if="isend==1">
                <p>
                  <span>{{$t('home.dejiangid')}}:</span>
                  <span class="gold-one">{{ttendobj.price_uid | yinfour}}</span>
                </p>
                <p>{{$t('home.auction_has_done')}}</p>
            </div>
            <div class="end-tips" v-if="isend==1">
               <span >{{$t('home.end_time')}}:</span>
               <!-- 结束时间  -->
               <span class="end-time" v-if="ttendobj.winningInfo">{{ttendobj.winningInfo.winningTime}}</span>
            </div>
          <div class="p3">
            <p class="pone"><span class="width54">
              <!-- 商品价值 -->
                {{$t('common.good_value')}}
              </span><i>${{obj.total_amount | number_format}}</i></p>
            <p class="ptwo" v-if="isend==0">
              <span class="width74"  :class="lang=='cn'?'':'width90'" >
                <!-- 当前竞拍价 -->
                {{$t('common.now')}}{{$t('common.auction_price')}}
                </span><i>${{changettData.now_price ? (changettData.now_price  ) : "0.00"  | number_format}}</i>
            </p>

            <p class="ptwo" v-if="isend==1">
              <span class="width74" :class="lang=='cn'?'':'width100'">
                <!-- 得奖竞拍价 -->
                {{$t('good.win')}}{{$t('common.auction_price')}}
                </span>
                <i v-if="ttendobj.winningInfo">${{(ttendobj.winningInfo.winning_price  ) | number_format }}</i>                
                <!-- <i>${{obj.winning_price ? (obj.winning_price  ) : "0.00"  | number_format }}</i> -->
            </p>


          </div>
          <div class="p3"> 
            <p class="pone"><span class="width54">
              <!-- 起拍价 -->
              {{$t('good.start_price')}}
              </span><i>${{obj.min_join_amount | number_format}}</i></p>
            <p class="ptwo" v-if="isend!=1"><span class="width74" :class="lang=='cn'?'':'width100'">
              <!-- 当前夺宝人ID -->
                {{$t('good.new')}}{{$t('good.golder')}}ID
              </span>
              <!-- <i>{{changettData.joinuid?(changettData.joinuid | yinfour):$t('mine.zhan_no')}}</i> -->
                <i v-if="changettData.joinuid">{{ changettData.joinuid  }}</i>
                <i v-else > {{ $t('mine.zhan_no') }}</i>
              
              </p>
          </div>
          <ul class="paybid">
            <li class="li1" @click="showDialog(60,1,isend==1||waitFlag)" :class="(isend==1||waitFlag)?'isend':''">
              <p :class="lang=='cn'?'':'fz10'">{{$t('good.reduce')}}1{{$t('good.a')}}Bid Token</p>
              <p class="fz12" :class="lang=='cn'?'':'fz10'">{{$t('auction.countdown')}}{{$t('auction.add')}}60{{$t('common.second')}}</p>
            </li>
            <li class="li2"   @click="showDialog(30,3,isend==1||waitFlag)"  :class="(isend==1||waitFlag)?'isend':''">
              <p :class="lang=='cn'?'':'fz10'">{{$t('good.reduce')}}3{{$t('good.a')}}Bid Token</p>
              <p class="fz12" :class="lang=='cn'?'':'fz10'">{{$t('auction.countdown')}}{{$t('auction.add')}}30{{$t('common.second')}}</p>
            </li>
            <li class="li3"  @click="showDialog(5,5,isend==1||waitFlag)"  :class="(isend==1||waitFlag)?'isend':''">
              <p :class="lang=='cn'?'':'fz10'">{{$t('good.reduce')}}5{{$t('good.a')}}Bid Token</p>
              <p class="fz12" :class="lang=='cn'?'':'fz10'">{{$t('auction.countdown')}}{{$t('auction.add')}}5{{$t('common.second')}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="main-bgfff" v-if="$route.query.type=='cb'">
        <div class="title">
          <h6>
            <span>{{obj.qishu_trans}} </span>
            <span class="good-value">${{(obj.total_amount?obj.total_amount:'0') | number_format}}</span>
          </h6>
          <h5>{{obj.title}}</h5>
          <p class="back" @click="gotoJiexiao">{{$t('home.before_zhongjiang')}} >></p>
        </div>
        <div class="cbid-box">
            <div class="cbid-box-process" v-if="changecbData.total_need">
                <van-progress :percentage="(changecbData.join_num/changecbData.total_need)*100" color="#DF561C" :show-pivot="false"/>
            </div>
            <div class="cbid-box-text" v-if="changecbData.total_need">
              <p class="p-left">{{$t('home.has_enter')}}: <span>{{changecbData.join_num}}</span></p>
              <p class="p-right">{{$t('auction.remaining_number')}}:<span>{{changecbData.total_need-changecbData.join_num}}</span> </p>
            </div>
            <div class="cb-start" v-if="isend!=1" >
              <van-button class="startbtn" @click="startCbBuy()">
                <!-- 开始竞拍 -->
                {{$t("auction.start_bet")}}
              </van-button>
              <div class="see-text"  >
                <p v-if="obj.lucky_num_list">
                  {{$t('good.my')}}{{$t('good.raffle_code')}}
                  <span v-if="obj.lucky_num_list.length>0">{{obj.lucky_num_list[0].lucky_num}}</span>
                  <span v-else class="p_no" >{{$t('good.no_enter_bet')}}</span> 
                  <i v-if="obj.lucky_num_list.length>0"  @click="gotoMyCodeList('mine-code',obj.lucky_num_list)">{{$t('common.seen')}}</i> </p>
                <!-- <p v-else >{{$t('good.my')}}{{$t('good.raffle_code')}} <i v-if="false" @click="gotowhere('mine-code')">{{$t('common.seen')}}</i> </p> -->
              </div>
            </div>
            <div class="cb-end " v-if="isend==1">
                <p class="p-end">{{$t('home.auction_has_done')}}</p>
                <div class="p-text" v-if="obj.lucky_num_list">
                  <!-- 我的抽奖码 已经结束 -->
                    <p v-if="obj.lucky_num_list.length>0">{{$t('good.my')}}{{$t('good.raffle_code')}}<span v-if="obj.lucky_num_list[0]">{{obj.lucky_num_list[0].lucky_num}}</span>  <i @click="gotoMyCodeList('mine-code',obj.lucky_num_list)">{{$t('common.seen')}}</i> </p>
                    <p v-else >{{$t('good.my')}}{{$t('good.raffle_code')}}<span class="no_enter_bet">{{$t('good.no_enter_bet')}}</span>   </p>
                </div>
                <div class="timeend" v-if="cbendobj.winningInfo">
                    <span>{{$t('good.first_qishu')}}{{$t('good.winning_code')}}</span>
                    <span class="gold-one">{{cbendobj.winningInfo.lucky_num}}</span>
                </div>
                <div class="gold-info" v-if="cbendobj.winningInfo">
                    <p>
                      <!-- <span>得奖人ID</span> -->
                      <span>{{$t('good.winner')}}ID</span>                      
                      <span>{{cbendobj.price_uid | yinfour}}</span>
                    </p>
                    <p >
                      <span>{{$t('good.func')}}</span>
                      <span class="good-func">{{cbendobj.winningInfo.winningFormula}}</span>
                    </p>
                </div>
            </div>
        </div>
      </div>
      <!-- tt 结束  是否本人中奖  -->
      <div class="gopay" v-if="$route.query.type=='tt'&&isend=='1'&&ttendobj.winningInfo.isWinner==1">

           <!-- 48h 内还未支付  -->
          <div v-if="ttendobj.winningInfo.winning_is_expired==0&&ttendobj.winningInfo.is_pay==0"  class="gopay-box"> 
              <p class="p-value">
                <!-- ฿ {{ttendobj.winningInfo.th_amount | number_format}} -->
                $ {{ttendobj.winningInfo.winning_price | number_format}}
              </p>
              <van-button class="btn-shai mybtn" @click="gotopaypricettend">
              {{$t('add.go_pay')}}
            </van-button>
            <!-- <van-button   v-if="false"   class="btn-disabled mybtn">
              {{$t('add.has_pay')}}
            </van-button> -->
          </div>
          
          <!-- 48h后 未支付  -->
          <div v-if="ttendobj.winningInfo.winning_is_expired==1&&ttendobj.winningInfo.is_pay==0"  class="gopay-box btn-bg"> 
              <p class="p-notpay">
                {{$t('add.not_pay')}}
              </p>
          </div>

          <!-- 本人已经支付  -->
          <div v-if="ttendobj.winningInfo.is_pay==1&&ttendobj.winningInfo.winning_is_expired==0"  class="gopay-box">
                <template v-if=" !ttendobj.winningInfo.is_mail_winning&&!ttendobj.winningInfo.is_sell_winning">
                    <van-button class="btn-you mybtn" @click="gotoAddress('mine-address',obj)">
                      {{$t('mine.youji')}}
                    </van-button>
                    <van-button class="btn-hui mybtn" @click="repayChange(obj)">
                      {{$t('mine.huishou')}}
                    </van-button>
                </template>
                <template v-else >
                    <van-button class="btn-you mybtn" @click="gotoAddress('mine-viewinfo',obj)">
                      {{$t('wallet.view_info')}}
                    </van-button>
                </template>
              <!-- <van-button v-if="obj.isSaidan==0" class="btn-shai mybtn" @click="gotoShaidan(obj)">
                {{$t('home.goshai')}}+5PV
              </van-button> -->
                <div v-if="obj.isSaidan==0" class="btn-shai mybtn" @click="gotoShaidan(obj)">
                    {{$t('home.goshai')}}+5PV 
                    <p class="mp aftermove"></p>
                </div>
              <van-button v-if="obj.isSaidan==1" class="btn-disabled mybtn">
                {{$t('mine.already_sai')}}
              </van-button>
            </div>
      </div>
      <div class="tabs">
          <div class="tabs-nav" >
                <van-tabs  scrollspy  @scroll="tabScroll" :sticky="true"  v-model="active" class="good-tab" :class="lang=='cn'?'':'small-tab'">
                    <van-tab :title="$t('good.good')+$t('good.detail')">
                        <div class="good-detail" v-html="obj.content">
                           
                        </div>
                    </van-tab>
                    <van-tab :title="$t('home.shaidan')+$t('home.area')">
                        <div class="good-shai bgcolor">
                          <div class="good-shai-box">
                              <div class="tt-sort">
                                    <span>{{$t('home.ai_sort')}}</span>
                                    <van-popover
                                      v-model="showTime"
                                      trigger="click"
                                      :actions="actions"
                                      @select="onSelectTime"
                                      class="tt-pop"
                                    >
                                      <template #reference>
                                        <p>
                                            <span class="tt-title">{{$t('home.time')}}</span>
                                            <img v-if="time==-1" class="tt-arrow" :src="require('@/assets/imgs/arrow-down.png')" alt="">
                                            <img v-if="time==1" class="tt-up" :src="require('@/assets/imgs/arrow-down.png')" alt="">
                                        </p>
                                      </template>
                                    </van-popover>

                                    <van-popover
                                      v-model="showLike"
                                      trigger="click"
                                      :actions="actions"
                                      @select="onSelectLike"
                                    >
                                      <template #reference>
                                        <p>
                                          <span class="tt-title">{{$t('home.like')}}</span>
                                            <img v-if="like==-1" class="tt-arrow" :src="require('@/assets/imgs/arrow-down.png')" alt="">
                                            <img v-if="like==1" class="tt-up" :src="require('@/assets/imgs/arrow-down.png')" alt="">
                                        </p>
                                      </template>
                                    </van-popover>
                                    <p class="count-right">{{$t('auction.total')}} {{shaidan.length}} {{$t('auction.no')}}{{$t('home.shaidan')}}</p>
                                </div>
                                <div class="good-shai-item" v-for="(l,i) in shaidan" :key="i">
                                    <div class="top-two">
                                        <div class="userinfo">
                                            <img class="user-avatar" alt="" v-lazy="l.winner_headimgurl" >
                                            <span class="user-name">{{l.user_uid | yinfour}}</span>
                                        </div>
                                        
                                        <div>
                                          {{l.from_now_time}}
                                        </div>
                                      </div>
                                      <div class="top-word van-ellipsis">
                                        {{l.content}}
                                        <!-- <img src="../../assets/imgs/a_pin.png" class="top-apin" alt=""> -->
                                      </div>
                                      <div class="img-list" v-if="l.show_pic[0]">
                                          <img v-for="(item,g) in l.show_pic" :key="g" class="img-detail" alt="" v-lazy="item" >
                                      </div>
                                      <div class="top-like">
                                        <p class="seen-btn" @click.stop="gotoGoodDetail(l)">{{$t('common.see_now')}}</p>
                                        <p class="like-num"  @click.stop="likeAdd(l)" :class="l.is_zan==1?'color-main':''">
                                          <van-icon name="like" class="heartBeat" />
                                          <span >{{l.zan}}</span>
                                        </p>
                                      </div>
                                </div>
                          </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('auction.join')+$t('auction.record')">
                        <div class="good-log bgcolor">
                          <div class="good-log-box">
                                <el-table
                                    :data="joinlist"    
                                    fit
                                    class="g-table"
                                    :cell-class-name="getCurrentCell"
                                    v-if="$route.query.type=='cb'&&joinlist.length>9"
                                    height="400"  
                                >
                                    <af-table-column align="left" label="ID"  prop="uid"  width="90"  >
                                      <template slot-scope="scope">
                                        {{scope.row.uid | yinfour}}
                                      </template>
                                    </af-table-column>
                                    <af-table-column align="center" width="auto" :label="$t('auction.frequency')"   prop="total" ></af-table-column>
                                    <af-table-column align="right"  width="140" :label="$t('home.time')"  >
                                      <template slot-scope="scope">
                                        {{scope.row.created_at || scope.row.send_time}}
                                      </template>
                                    </af-table-column>
                                </el-table>
                                <el-table
                                    :data="joinlist"    
                                    fit
                                    class="g-table"
                                    :cell-class-name="getCurrentCell"
                                    v-if="$route.query.type=='cb'&&joinlist.length<=9"
                                >
                                    <af-table-column align="left" label="ID"  prop="uid"  width="90"  >
                                      <template slot-scope="scope">
                                        {{scope.row.uid | yinfour}}
                                      </template>
                                    </af-table-column>
                                    <af-table-column align="center" width="auto" :label="$t('auction.frequency')"   prop="total" ></af-table-column>
                                    <af-table-column align="right"  width="140" :label="$t('home.time')"  >
                                      <template slot-scope="scope">
                                        {{scope.row.created_at || scope.row.send_time}}
                                      </template>
                                    </af-table-column>
                                </el-table>

                                <el-table
                                    :data="joinlist"    
                                    
                                    class="g-table"
                                    :cell-class-name="getCurrentCellOntt"
                                    v-if="$route.query.type=='tt'&&joinlist.length>9"
                                    height="400"
                                >
                                    <af-table-column label="ID" align="left" width="55"  prop="uid"  >
                                      <template slot-scope="scope">
                                        {{scope.row.uid | yinfour}}
                                      </template>
                                    </af-table-column>
                                    <af-table-column align="center" width="auto"   :label="$t('auction.add')+$t('home.time')"   prop="timeLast" >
                                        <template slot-scope="scope">
                                            {{scope.row.count?scope.row.count:scope.row.timeLast}}S
                                        </template>    
                                    </af-table-column>
                                    <af-table-column align="right" width="135" :label="$t('home.time')"  >
                                      <template slot-scope="scope">
                                        {{scope.row.created_at || scope.row.send_time}}
                                      </template>
                                    </af-table-column>
                                </el-table>
                                <el-table
                                    :data="joinlist"    
                                    class="g-table"
                                    :cell-class-name="getCurrentCellOntt"
                                    v-if="$route.query.type=='tt'&&joinlist.length<=9"
                                >
                                    <af-table-column label="ID" align="left" width="55"  prop="uid"  >
                                      <template slot-scope="scope">
                                        {{scope.row.uid | yinfour}}
                                      </template>
                                    </af-table-column>
                                    <af-table-column align="center" width="auto"   :label="$t('auction.add')+$t('home.time')"   prop="timeLast" >
                                        <template slot-scope="scope">
                                            {{scope.row.count?scope.row.count:scope.row.timeLast}}S
                                        </template>    
                                    </af-table-column>
                                    <af-table-column align="right" width="135" :label="$t('home.time')"  >
                                      <template slot-scope="scope">
                                        {{scope.row.created_at || scope.row.send_time}}
                                      </template>
                                    </af-table-column>
                                </el-table>
                                <p class="good-more" v-if="page<Math.ceil(maxtotal/pageSize)"   @click="getmoreJoinList"> <van-icon name="arrow-down" size="20" /> </p>
                                <van-divider>{{$t('auction.total')}} {{joinlistTotal}} {{$t('auction.no')}}{{$t('auction.record')}}</van-divider>
                              <!-- <el-table
                                  :data="joinlist"    
                                  fit
                                  class="g-table"
                                  :cell-class-name="getCurrentCell"
                                  v-if="$route.query.type=='cb'"
                                  height="400"
                              >
                                  <af-table-column align="left" label="ID"  prop="uid"  width="90"  >
                                     <template slot-scope="scope">
                                      {{scope.row.uid | yinfour}}
                                    </template>
                                  </af-table-column>
                                  <af-table-column align="center" width="auto" :label="$t('auction.frequency')"   prop="total" ></af-table-column>
                                  <af-table-column align="right"  width="140" :label="$t('home.time')"  >
                                    <template slot-scope="scope">
                                      {{scope.row.created_at || scope.row.send_time}}
                                    </template>
                                  </af-table-column>
                              </el-table>

                              <el-table
                                  :data="joinlist"    
                                  
                                  class="g-table"
                                  :cell-class-name="getCurrentCellOntt"
                                  v-if="$route.query.type=='tt'"
                                  height="400"
                              >
                                  <af-table-column label="ID" align="left" width="55"  prop="uid"  >
                                    <template slot-scope="scope">
                                      {{scope.row.uid | yinfour}}
                                    </template>
                                  </af-table-column>                           
                                  <af-table-column align="center" width="auto"   :label="$t('auction.add')+$t('home.time')"   prop="timeLast" >
                                      <template slot-scope="scope">
                                          {{scope.row.count?scope.row.count:scope.row.timeLast}}S
                                      </template>    
                                  </af-table-column>
                                  <af-table-column align="right" width="135" :label="$t('home.time')"  >
                                    <template slot-scope="scope">
                                      {{scope.row.created_at || scope.row.send_time}}
                                    </template>
                                  </af-table-column>
                              </el-table>
                              <p class="good-more"> <van-icon name="arrow-down" size="20" /> </p>
                              -->
                          </div>
                        </div>
                    </van-tab>
                    <van-tab :title="$t('rule.txt')">
                         <div class="good-rule bgcolor">
                          <div class="good-rule-box">
                              <h2>{{$t('rule.txt')}}</h2>
                              <div class="ques-list" v-if="$route.query.type=='cb'">
                                 <p class="rule_q">
                                   1.{{$t('rule.cb_play_q')}}
                                 </p>
                                 <p class="rule_a">
                                    {{$t('rule.cb_play_a')}} 
                                  </p>
                                   <p class="rule_a">
                                  {{$t('rule.cb_play_a2')}} 
                                  </p>
                              </div>
                              <div class="ques-list" v-if="$route.query.type=='cb'">
                                 <p class="rule_q">
                                   2.{{$t('rule.cb_rule_q')}}
                                 </p>
                                 <p class="rule_a">
                                    {{$t('rule.cb_rule_a')}} 
                                  </p>
                                   <p class="rule_a">
                                  {{$t('rule.cb_rule_a2')}} 
                                  </p>
                                  <p class="rule_a">
                                  {{$t('rule.cb_rule_a3')}} 
                                  </p>
                                  <p class="rule_a">
                                  {{$t('rule.cb_rule_a4')}} 
                                  </p>
                              </div>
                              <div class="ques-list" v-if="$route.query.type=='tt'">
                                 <p class="rule_q">
                                   1.{{$t('rule.tt_play_q')}}
                                 </p>
                                 <p class="rule_a">
                                    {{$t('rule.tt_play_a')}} 
                                  </p>
                                  <p class="rule_a">
                                    {{$t('rule.q0')}} 
                                  </p>
                                   <p class="rule_a">
                                    {{$t('rule.q1')}} 
                                  </p>
                                   <p class="rule_a">
                                    {{$t('rule.q2')}} 
                                  </p>
                                   <p class="rule_a">
                                    {{$t('rule.q3')}} 
                                  </p>
                              </div>
                              <div class="ques-list" v-if="$route.query.type=='tt'">
                                 <p class="rule_q">
                                   2.{{$t('rule.tt_rule_q')}}
                                 </p>
                                 <p class="rule_a">
                                    {{$t('rule.tt_rule_a')}} 
                                  </p>
                                   <p class="rule_a">
                                    {{$t('rule.q4')}} 
                                  </p>
                              </div>
                          </div>
                        </div>
                    </van-tab>
                </van-tabs>
          </div>
      </div>
    </div>

     <van-popup class="good-popup" v-model="show" position="bottom"  :style="{ height: '8rem' }" duration="0.2" >
        <div class="good-popup-box">
             <div class="box-top">
                <p>{{$t('auction.start_bet')}}</p>
             </div>
             <div class="box-yue">
               <p>{{$t('wallet.try_gold')}}{{$t('auction.balance')}}</p>
              <p> <span>{{myyue | int_format}}</span> <span>Bid Token</span> </p>
             </div>
             <div class="box-num">
                <div class="">{{$t('auction.quantity')}}</div>
                <div class="box-num-right">
                  <van-stepper v-model="value" :max="changecbData.total_need-changecbData.join_num" />
                </div>
                
             </div>
             <p class="max-tips">* {{$t('add.max_tips')}}</p>
             <div class="btn-list">
               <van-button class="btns" @click="changeNum(0.1)" >10%</van-button>
               <van-button class="btns" @click="changeNum(0.2)" >20%</van-button>
               <van-button class="btns" @click="changeNum(0.3)" >30%</van-button>
               <van-button class="btns" @click="changeNum(0.4)" >40%</van-button>
               <van-button class="btns" @click="changeNum(0.5)" >50%</van-button>
             </div>
             <div class="popup-box">
                <div class="popup-box-process"  v-if="changecbData.total_need">
                  <van-progress :percentage="(changecbData.join_num/changecbData.total_need)*100" color="#DF561C" :show-pivot="false"/>
                </div>
                <div class="popup-box-text">
                  <p class="p-left">{{$t('home.has_enter')}}: <span>{{changecbData.join_num}}</span></p>
                  <p class="p-right">{{$t('auction.remaining_number')}}:<span>{{changecbData.total_need-changecbData.join_num}}</span> </p>
                </div>
             </div>
             
            <van-button class="sure-btn"  @click="cbOneByAction" >{{$t('login.sure')}}</van-button>
        </div>
    </van-popup>

     <van-overlay class="g-overlay" :show="ttshow"  @click="ttshow=false">
          <div class="good-mask" @click.stop>
              <div class="top">
                {{$t('auction.pay_success')}}
              </div>
              <img class="icon" :src="require('@/assets/images/success.png')" alt="">
              <p class="text"> {{$t('auction.countdown')}}{{$t('auction.add')}} {{addNum}} {{$t('common.second')}}</p>
              <van-button class="btn" @click="clickShowTTPay()">{{$t('login.sure')}}</van-button>
          </div>
        </van-overlay>

        <!-- 新手体验完毕  3 BiD token 使用完毕  如何判断  -->
        <van-overlay class="g-overlay" :show="nomoeny" @click="nomoeny=false">
          <div class="good-mask width300" @click.stop>
              <div class="top">
                  {{$t('wallet.not_balance')}}
              </div>
              <img class="nomoeny" :src="require('@/assets/images/nomoney.png')" alt="">
              <p class="text"> {{$t('wallet.Experience_time')}}</p>
              <van-button class="btn" @click="gotowhere('main-walletIndex')">{{$t('wallet.go_reset')}}</van-button>   
          </div>
          <img @click="nomoeny=false" class="close-btn" :src="require('@/assets/images/closeicon.png')" alt="">
        </van-overlay>

        <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one money-pay" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="pinform" @submit="payCloudBid">
                <p v-if="ttendobj.winningInfo" class="pay-account">{{$t('wallet.pay')}}:${{ttendobj.winningInfo.winning_price | number_format}}</p>
                <van-field
                v-model="pin"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="pin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[
                  { required: true, message: $t('login.required_sec_pass') },
                  { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                ]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="launchForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>

    <!-- 回收奖品 -->
      <van-overlay class="g-overlay" :show="repay" >
        <div class="repay-mask" @click.stop>
              <div class="top">
                  {{$t('mine.huishou')}}
              </div>
              <div class="title">{{$t('mine.is_need_huishou')}}</div>
              <p class="ptext">{{$t('mine.your_need_rate')}}<span v-if="user">{{user.sell_winning_rate * 100 | number_format}}%</span>,{{$t('mine.after_back')}}</p>
              <p class="ptext">{{$t('mine.you_get')}}<span  v-if="user">{{obj.total_amount*(1-user.sell_winning_rate) | number_format }}PV</span></p>
              <div class="overlay-btns">
                  <van-button class="btn btn-cancel" @click="repay=false" >{{$t('mine.no')}}</van-button>
                  <van-button class="btn btn-sure"  @click="sellwinning()">{{$t('mine.yes')}}</van-button>
              </div>
        </div>
      </van-overlay>

      <!-- 确认竞拍 -->
        <van-overlay class="g-overlay" :show="isPay" >
          <div class="repay-mask isPay-mask" @click.stop>
                <div class="top">
                  <!-- 参与竞拍 -->
                    {{$t('auction.join')}} {{$t('home.bet')}}
                </div>
                <div class="title">
                  <!-- 是否确认竞拍? -->
                  {{$t('good.is_go_bet')}}
                </div>
                <div class="overlay-btns">
                    <van-button class="btn btn-cancel" @click="isPay=false" >{{$t('mine.no')}}</van-button>
                    <van-button class="btn btn-sure"  @click="payCloudBid()">{{$t('mine.yes')}}</van-button>
                </div>
          </div>
      </van-overlay>

      <!-- 新手体验 弹框 -->
      <van-overlay class="g-overlay" :show="newshow">
            <div class="new-mask" @click.stop>
                <div class="top">
                  {{$t('news.news_user')}}
                </div>
                <p class="text">{{$t('news.news_tips')}}</p>
                <div class="mask-box"  v-if="user">
                        <van-cell :title="$t('info.user_real')"  >
                            <template #extra>
                                <p class="p-extra" v-if="user.is_kyc==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                <van-button v-else class="extra-btn " :class="lang=='cn'?'':'small-size'"  @click="gotowhere('mine-realname')">
                                    {{ user.is_kyc == 3 ? $t('mine.kyc'+user.is_kyc) : $t('news.go_real')  }}
                                </van-button>
                                
                                <!-- <van-button v-else class="extra-btn" @click="gotowhere('mine-realname')">{{$t('news.go_real')}}</van-button> -->
                            </template>
                        </van-cell>
                        <van-cell :title="$t('news.bind_bank_card')">
                            <template #extra>
                                 <p class="p-extra" v-if="user.isbank==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                 <van-button v-else class="extra-btn" @click="gotowhere('mine-card')">{{$t('news.go_bind')}}</van-button>
                            </template>
                        </van-cell>
                        <van-cell :title="$t('info.add_address')" >
                            <template #extra >
                                <p class="p-extra" v-if="user.is_addr==1">
                                    <img class="dui-img" :src="require('@/assets/images/dui.png')" alt="">
                                </p>
                                 <van-button v-else class="extra-btn" @click="gotowhere('mine-address-add')">{{$t('news.go_bind')}}</van-button>
                            </template>
                        </van-cell>
                </div>
                <van-button class="btn" @click="newshow=false" >{{$t('login.sure')}}</van-button>
            </div>
        </van-overlay>


        <!-- cb 中奖  -->
        <van-overlay class="g-overlay" :show="cbhasyes" :lock-scroll="true">
            <div class="cb-mask" @click.stop>
                <div class="result-box">
                  <img @click.stop="cbhasyes=false" class="img-close" :src="require('@/assets/imgs/cb-yes.png')" alt="">
                  <img class="result-img" :src="require('@/assets/imgs/cb-hasyes.png')" alt="">
                </div>
                <p class="result-text">{{$t('add.cb_yes')}}</p>
            </div>
        </van-overlay>
        <!-- cb 未中奖  -->
        <van-overlay class="g-overlay" :show="cbhasno" :lock-scroll="true">
            <div class="cb-mask" @click.stop>
                <div class="result-box">
                  <img @click.stop="cbhasno=false" class="img-close" :src="require('@/assets/imgs/cb-no.png')" alt="">
                  <img class="result-img" :src="require('@/assets/imgs/cb-hasno.png')" alt="">
                </div>
                <p class="result-text result-text-no">{{$t('add.cb_no')}}</p>
            </div>
        </van-overlay>
        <!-- 倒计时  cb  -->
        <van-overlay class="g-overlay" :show="cbtimedown" lock-scroll>
            <div class="cb-mask" @click.stop.prevent>
                <div class="timing-box">
                  <van-count-down
                      v-if="changecbData.drawing_countdown"
                      ref="cbtimedom"
                      :time="changecbData.drawing_countdown * 1000"
                      format="ss"
                      class="down-float "
                      :class="moveClass"
                      @change="cbtimechange"
                      @finish="getcbresultend"
                  />
                  <img class="timing-img" :src="require('@/assets/imgs/cb-timing.png')" alt="">
                  <img @click="hiddenTimeing" class="close-btn-cb" :src="require('@/assets/images/closeicon.png')" alt="">
                </div>
            </div>
        </van-overlay>

        <div class="timing-box small-cb" v-if="cbtimedownsmall">
          <van-count-down
              v-if="changecbData.drawing_countdown"
              ref="cbtimedom"
              :time="changecbData.drawing_countdown * 1000"
              format="ss"
              class="down-float "
              :class="moveClass"
              @change="cbtimechange"
              @finish="getcbresultend"
          />
            <img class="timing-img" :src="require('@/assets/imgs/cb-timing.png')" alt="">
        </div>
        <!-- 分享   -->
          <van-popup class="login-popup pay-one money-pay" v-model="shareshow" position="bottom"   duration="0.2" >
              <div class="login-popup-box" >
                  <div class="pop-top">
                      <p>{{$t('login.confirm')}}{{$t('add.share')}}</p>
                      <van-icon name="cross" class="pop-close" @click="shareshow=false" />
                  </div>
                  <div class="share-box" v-if="dashboard.recommend_code">
                      <div 
                          class="share-li fb-share-button bounce" 
                          :data-href="$host+'app/index.html#/register?recommend_code='+dashboard.recommend_code" 
                          data-layout="button" 
                          data-size="large">
                          <a target="_blank" 
                          :href="'https://www.facebook.com/sharer/sharer.php?u='+$host+'app/index.html#/register?recommend_code='+dashboard.recommend_code+'&amp;src=sdkpreparse'" 
                          class="fb-xfbml-parse-ignore">
                              <svg class="share-icon" aria-hidden="true">
                                  <use xlink:href="#iconFACEBOOK"></use>
                              </svg>
                              <p>FaceBook</p>
                          </a></div>

                          <LineBtn :sharelink="$host+'app/index.html#/register?recommend_code='+dashboard.recommend_code"/>
                  </div>
              </div>
          </van-popup>
  </div>
</template>
<script>
// cb  cloud Bid
// tt  time twister

// isend 1  已经结束
// isend 0  没有结束
import {Toast,Notify} from "vant"
import {randomString,myinfo} from "@/common"
import {passReg} from "@/common/validate"
import {mapState} from "vuex"
// import { setTimeout } from 'timers';
export default {
  mixins:[myinfo],
  name: "goods",
  data() {
    return {
      shareshow:false,
      cbhasno:false,
      cbhasyes:false,
      cbtimedown:false,
      cbtimedownsmall:false,

      moveClass:"",
      ruleIndex:0,
      isPay:false,
      lang:localStorage.getItem("langData") || 'th',
      newshow:false,
      repay:false,// 回收
      is_pay:false,
      ttendobj:{},
      cbendobj:{},
      winningInfo:{},
      isend:this.$route.query.isend,
      joinlist:[],   // 参与记录
      joinlistTotal:0, // 参与记录条数
      shaidan:[],  // 晒单列表
      timeCount:0, // tt 倒计时时间 
      cbpay:true, // cb 竞拍支付  
      spen:1,  // 消耗的 Bid Token 
      addNum:60,
      getscrollData:false,
      passPattern:passReg,
      pin:'', // 二级支付密码
      secshow:false,
      myyue:0,
      obj:[], // 当前商品数据   null 会卡顿
      nomoeny:false,
      imgSrc:'http://47.104.209.44/base/img/1.jpg',
      ttshow: false,
      value: 1,
      active: 0,
      show: false,
      topSwiper: [
        "http://47.104.209.44/base/img/1.jpg",
        "http://47.104.209.44/base/img/5.jpg",
        "http://47.104.209.44/base/img/2.jpg",
        "http://47.104.209.44/base/img/3.jpg"
      ],
      showTime: false,
      showLike: false,
      time: -1,
      like: -1,
      actions: [
        { text: this.$t("common.aesc"), value: 1 },
        { text: this.$t("common.desc"), value: -1 }
      ],
      pt3:"password",
      list:[],
      goodShow:false,
      goodDetail:false,
      changettData:{},  // 实时更新的数据  
      changecbData:{},
      waitFlag:false,

      page:1,
      pageSize:20,
      maxtotal:0,
    };
  },
  beforeRouteEnter(to, from, next) {
      if (from.name == 'mine-code') { // 这个name是下一级页面的路由name
         to.meta.isBack = true; // 设置为true说明你是返回到这个页面，而不是通过跳转从其他页面进入到这个页面
      }
      next()
  },
  computed:{
    ...mapState(['jiqigoodList','homewinnerList'])
  },
  watch:{
    value(v){
      // console.log(v);
    },
    secshow(v){
      if(!v){
        // 滑动到顶部
        $("html,body").animate({   //在body内获取滚动条
          scrollTop: '0px'   //获取滚动条的高度，设置它距离顶部的距离为0
        }, 100)
      }
    },
    ttshow(v){
      if(!v){
        // 滑动到顶部
        $("html,body").animate({   //在body内获取滚动条
          scrollTop: '0px'   //获取滚动条的高度，设置它距离顶部的距离为0
        }, 100)
      }
    },
    show(v){
      if(!v){
        // 滑动到顶部
        $("html,body").animate({   //在body内获取滚动条
          scrollTop: '0px'   //获取滚动条的高度，设置它距离顶部的距离为0
        }, 100)
      }
    },
    isPay(v){
      if(!v){
        // 滑动到顶部
        $("html,body").animate({   //在body内获取滚动条
          scrollTop: '0px'   //获取滚动条的高度，设置它距离顶部的距离为0
        }, 100)
      }
    },
    
    changecbData:{
      deep:true,
      handler(newval){
        // if(newval.total_need==newval.join_num){
        //     this.getcbResult();  // 没有库存  获取最终竞拍结果
        // }
         if(newval.total_need==newval.join_num&&newval.is_drawing==2){
              this.getcbResult();  // 没有库存  获取最终竞拍结果
          }else if(newval.total_need==newval.join_num&&newval.is_drawing==1){
              this.cbtimedown = true ;   // 60S 倒计时 
          }
      }
    },
    homewinnerList(v){
        
        // 推送 中奖消息  
        //  cb 

        v.forEach(item=>{
           var data = item[this.$route.query.type+'_winninginfo'];
           if(data){
              if(this.$route.query.type=="cb"){
                  if(this.$route.query.gid==data.good_id&&this.$route.query.qishu==data.qishu){  
                    // 测试成功 
                    // 这期商品 开奖 
                    this.isend = "1"
                    this.changecbData = {
                        ...this.changecbData,
                        join_num:this.changecbData.total_need
                    }
                    this.cbendobj = {
                      price_uid:data.uid,
                      winningInfo:data.winnerInfo
                    }
                  }
              }
              if(this.$route.query.type=="tt"){
                if(this.$route.query.gid==data.good_id&&this.$route.query.qishu==data.qishu){  
                    // 测试失败 
                    // 这期商品 开奖 tt
                    this.isend = "1";
                    this.waitFlag = false;
                    this.ttendobj = {
                      ...this.ttendobj,
                      price_uid:data.uid,
                      winningInfo:{
                        ...this.ttendobj.winningInfo,
                        ...data.winnerInfo,
                      }
                    }
                  }
              }
           }
        })
        // tt 
    },
    jiqigoodList(v){
      // console.log(v)
      if(this.isend=="0"){   // 没有结束
          var new_data = v[0];
          if(new_data['buy_'+this.$route.query.type]){  
            // 对应类型的数据 
            let data = new_data['buy_'+this.$route.query.type];
            if(data.qishu==this.$route.query.qishu&&data.good_id==this.$route.query.gid){   // 匹配好对应的期数  
                if(this.$route.query.type=="tt"){
                  // 实时更新竞拍的相关数据  
                  this.changettData = {
                    now_price:data.now_price,
                    joinuid:data.uid
                  }
                  
                  // 更新 timeCount
                  let {day,hours,mins,second} = data;
                  this.timeCount = parseInt(day) * 24 * 3600 * 1000 + parseInt(hours)  * 3600 * 1000 + parseInt(mins)  *60 * 1000 + parseInt(second)  * 1000;
                }

                if(this.$route.query.type=="cb"){
                  // 更新数据  
                  this.changecbData = {
                    ...this.changecbData,
                    join_num:this.changecbData.join_num+data.total * 1,
                    drawing_countdown:data.drawing_countdown,
                    is_drawing:data.is_drawing
                  }

                }
              
                let t = document.getElementsByClassName('el-table__body-wrapper')
                // this.list.unshift(data);
                // console.log(data);
                setTimeout(() =>{
                  // this.joinlist.splice(5,1)
                  t[0].style.transition = 'all .5s'
                  t[0].style.marginTop = '48px'
                },500)
                setTimeout(() =>{
                  this.joinlist.unshift(data)
                  this.joinlistTotal+=1;  // 累计新增一条数据 
                  t[0].style.transition = 'all 0s ease 0s'
                  t[0].style.marginTop = '0'
                },1000)
            }
          }
      }
    }
  },
  mounted() {
      this.getGoodData();
      this.getGoodMoreData();  // 获取下拉数据 
  },
  
  methods: {
      hiddenTimeing(){
        this.cbtimedown = false;
        this.cbtimedownsmall = true;
      },
     getmoreJoinList(){
        this.$ajax.shaidanJoinlist({
              good_id:this.$route.query.gid,
              qishu:this.$route.query.qishu,
              page:this.page,
              pageSize:this.pageSize
          })
          .then(res=>{
              if(res.code==200){
                  this.$nextTick(()=>{
                      var list = []
                      for(var i in  res.data.joinlist){
                        list.push(res.data.joinlist[i])
                      }
                      this.joinlist  = this.joinlist.concat(list)
                      this.maxtotal = res.data.joinlist_total
                      this.page +=1;
                  })
              }
          })
        
    },
    shareshowAction(){
        var access_token = localStorage.getItem("access_token"); 
        if(access_token){
           this.shareshow = true;
        }else{
            this.$router.push({name:'signin'})
        }
    },
    getcbresultend(){
        this.changecbData = {...this.changecbData,is_drawing:-1}
        this.cbtimedown = false;
        this.cbtimedownsmall  = false;
        this.getcbResult();  // 没有库存  获取最终竞拍结果
    },
    cbtimechange(value){
      this.moveClass="bounceOut";
      setTimeout(()=>{
        this.moveClass="";
      },900)
    },
    // 回收
    sellwinning(){
      this.$ajax.sellwinning({
          form_token:randomString(),
          win_orderid:this.ttendobj.winningInfo.winning_orderid,
      })
      .then(res=>{
          if(res.code==200){
              this.repay = false;
              this.getTTResult()
          }
      })          
    },
    // 需要对字段 
    repayChange(item) {
       if(this.ttendobj.winningInfo.is_mail_winning || this.ttendobj.winningInfo.is_sell_winning){   // 判断是否邮寄 或者被回收  0 没有 
        Notify({
            message: this.$t('add.has_sall'),
            color: '#ad0000',
            background: '#ffe1e1',
        });
      }else{
        this.repay = true;
      }
    },
    gotoShaidan(item) {
      this.$router.push({
        name: "result-edit",
        query: {
          gid: this.ttendobj.winningInfo.good_id,
          qishu: this.ttendobj.winningInfo.qishu,
          orderid: this.ttendobj.winningInfo.winning_orderid,
        },
      });
      localStorage.removeItem("goodonedetail")
      localStorage.setItem("goodonedetail",JSON.stringify({...this.obj,...this.ttendobj.winningInfo}))
    },
    // 需要对字段 
    gotoAddress(name,item){
         this.$router.push({
          name,
          query:{
            win_orderid:this.ttendobj.winningInfo.winning_orderid,
            is_you:true,
            active_id:this.obj.active_id   // 判断是 tt 还是 cb 
          }
        });
    },
    // 需要对字段 
    gotopaypricettend(){
       this.secshow = true;
       this.is_pay = true;
    },
    gotoMyCodeList(name,list){
      this.$router.push({
        name,
        query:{
          good_id:this.$route.query.gid,
          qishu:this.$route.query.qishu,
        }
      })
    },
    timeEndAction(){
      
      // 倒计时结束 
      this.getTTResult();
      
      // this.getGoodData()
    },
    getGoodData(){
      // 商品数据
      this.$ajax.productdetail({
        id:this.$route.query.gid,
        qishu:this.$route.query.qishu,
      })
      .then(res=>{
        if(res.code==200){
          if(this.$route.query.type=='cb'){
              let {join_num,total_need,is_drawing,drawing_countdown} = res.data.product;
              this.changecbData = {
                join_num,
                total_need,                
                is_drawing,
                drawing_countdown,
              }
              // if(join_num==total_need){  // 产品无库存  
              //     this.getcbResult()
              // }
          }
          if(this.$route.query.type=='tt'){
             let {day,hours,mins,second} = res.data.product.countdown;
             this.timeCount = parseInt(day) * 24 * 3600 * 1000 + parseInt(hours)  * 3600 * 1000 + parseInt(mins)  *60 * 1000 + parseInt(second)  * 1000;
             this.changettData = {
                joinuid:res.data.product.joinuid,
                now_price:res.data.product.now_price
             }
             if(res.data.product.is_end==1  ){ // 这期商品已经结束
                this.getTTResult()
             }
            //  if(this.timeCount==0){ // 倒计时结束
            //     this.getTTResult()
            //  }
          }
          this.obj = res.data.product;
          // this.myyue = res.data.balance;
          this.goodShow = true;
        }
      })
    },
    // 查询 tt 的竞拍结果 
    getTTResult(){
        this.$ajax.checkTimetwisterWinner({
          good_id:this.$route.query.gid,
          qishu:this.$route.query.qishu,
          form_token:randomString()
        })
        .then(res=>{
            if(res.code==200){
              if(res.data.is_end==1){  // 已经结束
                  this.isend=1;
                  // this.ttendobj = res.data;
                  this.ttendobj = {...this.ttendobj,...res.data}
                  this.winningInfo = res.data.winningInfo;
              }else{
                  let {day,hours,mins,second} = res.data;
                  this.timeCount = parseInt(day) * 24 * 3600 * 1000 + parseInt(hours)  * 3600 * 1000 + parseInt(mins)  *60 * 1000 + parseInt(second)  * 1000;
                  // 时间还有 继续倒计时  
                  // 小于零  才显示等待开奖中  
                  if(this.timeCount<=0){
                    this.waitFlag = true;
                  }
              } 
            }
        })
    },
    // 查询 cb  的竞拍结果
    getcbResult(){
      
      this.$ajax.checkCloundbidWinner({
          good_id:this.$route.query.gid,
          qishu:this.$route.query.qishu,
          form_token:randomString()
        })
        .then(res=>{
            if(res.code==200){
              if(res.data.is_end==1){  // 已经结束
                this.isend=1;
                this.cbendobj = res.data;
                if(this.obj.lucky_num_list.length>0){   // 已经参与了  才会有这个判断
                      if(res.data.winningInfo.isWinner==1){
                        this.cbhasyes = true;
                      }else{
                        this.cbhasno = true;
                      }
                  }
                // if(res.data.winningInfo.isWinner==1){
                //   this.cbhasyes = true;
                // }else{
                //   this.cbhasno = true;
                // }
              }
            }
        })
    },

    // 获取商品下面的数据
    getGoodMoreData(){
      this.$ajax.shaidanJoinlist({
            good_id:this.$route.query.gid,
            qishu:this.$route.query.qishu,
            page:this.page,
            pageSize:this.pageSize
        })
        .then(res=>{
            if(res.code==200){
                this.$nextTick(()=>{
                    this.joinlist = res.data.joinlist;
                    this.joinlistTotal = res.data.joinlist_total;
                    this.shaidan = res.data.shaidan;
                    this.list = res.data.joinlist
                    this.page +=1;
                    this.maxtotal = res.data.joinlist_total;
                })
            }else{
              this.getscrollData = false;
            }
        })
    },
    // 监听 选项卡滑动  
    tabScroll({scrollTop,isFixed}){
      if(isFixed&&!this.getscrollData){
          // 晒单数据
        this.getscrollData = true;
      }
    },

    changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
    },
    clickShowTTPay(){
       this.ttshow  = false;
      //  this.secshow = false;
      //  this.cbpay = false;
      //  this.is_pay = false;
    },
    launchForm(){
      this.$refs.pinform.submit();
    },
    payCloudBid(){
  
      // 先判断是否是 tt 支付金额 
      if(this.is_pay){
        this.$ajax.payttwinningorder({
          form_token:randomString(),
          win_orderid:this.ttendobj.winningInfo.winning_orderid,
          pin:this.pin
          }).then(res=>{
              if(res.code==200){
                  this.getTTResult();  // 得到 状态
                  this.secshow = false;
                  this.item = {};
                  Notify({ type: 'success', message: res.msg });
              }
          })
      }else{

          if(this.cbpay){
            //  ONE CLOUND 用户竞拍 
            if(this.value>=(this.changecbData.total_need-this.changecbData.join_num)){
              this.value = this.changecbData.total_need-this.changecbData.join_num
            }
            this.$ajax.getcloundonetry({
              form_token :randomString(),
              good_id:this.obj.id,
              qishu:this.obj.qishu,
              total:this.value,
              pin:this.pin
            })
            .then(res=>{
                this.isPay = false;
                this.secshow = false;
                if(res.code==200){
                  // setTimeout(this.getcbResult,5*1000*60)
                  this.getGoodData();  // 获取我的抽奖码
                  // this.myyue = res.data.after_balance;   //获取最新的余额 
                  Notify({ type: 'success', message: res.msg});
                }

                // 新手提示 余额用玩了 
                if(this.$route.query.isnew=="1"){
                  if(res.code==403){
                    // 可能余额不足  3 bid token 
                    Notify.clear();
                    if(!res.data.is_try_bonus_end){  // 余额不足   需要修改 
                      this.nomoeny = true;
                    }
                  }
                }
            })
        }else{
          // Time Twister 用户竞拍
          this.$ajax.graptimetwistertry({
            pin:this.pin,
            form_token :randomString(),
            total:this.spen,
            spen:this.spen,
            qishu:this.obj.qishu,
            good_id:this.obj.id,
          })
          .then(res=>{

            if(res.code==200){
                this.isPay = false;
                this.secshow = false;
                this.ttshow = true;   // 显示成功
                // 直接去 ws 里面获取数据
                // this.getGoodData();   // 更新当前夺宝人id   添加时间就不会去 查询最新结果 
                // let {day,hours,mins,second} = res.data;
                // this.timeCount = parseInt(day) * 24 * 3600 * 1000 + parseInt(hours)  * 3600 * 1000 + parseInt(mins)  *60 * 1000 + parseInt(second)  * 1000;
                // Notify({ type: 'success', message: res.msg});
            }
            // 新手提示 余额用玩了 
            if(this.$route.query.isnew=="1"){
              if(res.code==403){
                // 可能余额不足  3 bid token 
                Notify.clear();
                if(!res.data.is_try_bonus_end){  // 余额不足
                  this.nomoeny = true;
                }
              }
            }
          })
        }
    }
      
    },
    cbOneByAction(){
        // this.secshow =true;
        this.isPay = true;  // 确认支付  
        this.show =false;
        this.cbpay = true;
        this.is_pay = false;
    },
    changeNum(n){
      this.value = Math.floor(this.obj.total_amount * n);

      
      // this.value = Math.floor(this.myyue * n);
      // this.value = Math.floor(( this.obj.total_need - this.obj.join_num) * n) 
    },
    gotowhere(name){
          this.$router.push({
              name
          })
      },
    getCurrentCell({ row, column, rowIndex, columnIndex }) {
      if(columnIndex==1){
          return 'color-main'
      }
    },
    gotoGoodDetail(item) {
      this.$router.push({
        name: "goods",
        query: {
          type: this.cb ? "cb" : "tt",
          isend: 1, // 已经结束
          gid: item.good_id,
          qishu: item.qishu,
        },
      });
    },

    // 时间排序
    onSelectTime(data) {
      this.time = data.value;
      this.sortfunc(this.time == 1 ? true : false, "created_at");
    },
    // 点赞排序
    onSelectLike(data) {
      this.like = data.value;
      this.sortfunc(this.like == 1 ? true : false, "zan");
    },
    // a-b 升
    // b-a 降 
    sortfunc(flag,field){
        function sortable(a,b){
            if(field=="created_at"){
                return  flag?new Date(a[field]).getTime()-new Date(b[field]).getTime():new Date(b[field]).getTime()-new Date(a[field]).getTime()
            }else{
                return flag?a[field]-b[field] : b[field]-a[field]
            }
        }
        this.shaidan = this.shaidan.sort(sortable)
    },
    likeAdd(item) {
      // Toast.success("点赞成功");
      this.$ajax.shaidanZan({
        saidanid:item.id
      })
      .then(res=>{
        if(res.code==200){
            Notify({ type: 'success', message: res.msg});
            this.shaidan = this.shaidan.map(v=>{
                if(v.id==item.id){
                    v={...v,zan:v.zan+1,is_zan:1}
                }
                return v;
            });
        }
       
      })
    },
    getCurrentCellOntt({ row, column, rowIndex, columnIndex }) {
      
      if(columnIndex==1){
          return 'color-main'
      }
    },
    // cb 竞拍 
    startCbBuy(){
        // is_try_bonus 三个认证没有完成
        // 不是新手进来 直接竞拍
        // 新手进来  已经认证完毕   直接竞拍
        // 新手进来   认证不成功    弹框提示 
      if(this.$route.query.isnew=="1"&&!this.user.is_try_bonus){
        // 新手体验  
        this.newshow  = true;
      }else{
        if(this.cbtimedown||this.cbtimedownsmall){
              Notify({
                message: this.$t('add.wait_son'),
                color: '#ad0000',
                background: '#ffe1e1',
            });
          }else{
              this.$ajax.walletmy()
              .then(res=>{
                  if(res.code==200){
                      this.myyue = res.data.list.find(item=>item.current_id==1034).usable_balance;
                  }
              })
              this.show = true;
          }
      }
      
    },
    // tt 竞拍 
    showDialog(num,spen,flag) {
      if(!flag){
          if(this.$route.query.isnew=="1"&&!this.user.is_try_bonus){
            // 新手体验  
            this.newshow  = true;
          }else{
              // this.secshow = true;
              this.isPay = true;
              this.addNum = num;
              this.spen = spen;
              this.cbpay = false;
              this.is_pay = false;
          }
      }
    },
    gotoJiexiao() {
      // 点击往期中奖
      this.$router.push({
        name: "jiexiao",
        query: {
          active: this.$route.query.type == "cb" ? "1" : "2"
        }
      });
    }
  }
};
</script>
